export default {
  lb: {
    name: "乐吧",
    ig_official: {note: '乐吧', input: "com.leba.mall"},
    id: 1,
    vip: true,
    funs: [
      {
        name: "领取积分",
        checked: true,
      },
      {
        name: "提取积分",
        checked: true,
      }
    ]
  },
  xxyz: {
    name: "仙侠宇宙",
    ig_official: {note: '仙侠宇宙', input: "com.ainimei.farmworld"},
    id: 1,
    vip: true,
    funs: [
      {
        name: "抢夺资源",
        checked: true,
      }
    ]
  },
  hgmfdj: {
    name: "红果短剧",
    ig_official: {note: '红果短剧', input: "com.phoenix.read"},
    id: 1,
    vip: true,
    funs: [
      {
        name: "一键日常",
        checked: true,
      }
    ]
  },
  sspd: {
    name: "松鼠派对",
    ig_official: {note: '松鼠派对', input: "uni.UNIF0A42FA"},
    id: 1,
    vip: true,
    funs: [
      {
        name: "每日任务",
        checked: true,
      },
      {
        name: "转盘",
        checked: false,
      },
      {
        name: "领取松果",
        checked: false,
      }
    ]
  },
  sszx: {
    name: "盛世甄选",
    ig_official: {note: '盛世甄选', input: "com.shengshi.selection"},
    id: 1,
    vip: true,
    funs: [
      {
        name: "宝石转赠",
        checked: true,
      }
    ]
  },
  lzyz: {
    name: "量子宇宙",
    ig_official: {note: '量子宇宙', input: "com.lzyz.www"},
    id: 1,
    vip: true,
    funs: [
      {
        name: "自动合成",
        checked: true,
      },
      {
        name: "转盘",
        checked: true,
      },
    ]
  },
  kxjz: {
    name: "开心酒庄",
    ig_official: {note: '开心酒庄', input: "guangdongai.com"},
    id: 1,
    vip: true,
    funs: [
      {
        name: "酿酒",
        checked: true,
      },
    ]
  },
  coinworld: {
    name: "金币世界",
    ig_official: {note: '金币世界', input: "com.atanytime.sschat"},
    id: 1,
    vip: true,
    funs: [
      // {
      //   name: "激活奖励",
      //   checked: false,
      // },
      // {
      //   name: "抽奖",
      //   checked: true,
      //   switch: false,
      //   title: '放弃翻倍'
      // },
      // {
      //   name: "开宝箱",
      //   checked: true,
      // },
      {
        name: "自动合成",
        checked: true,
      },
      // {
      //   name: "智能合成",
      //   checked: true,
      //   explain: '该功能为脚本进行等级识别合成，未满级请使用此功能（含自动回收）'
      // },
      {
        name: "签到",
        checked: true,
      },
      {
        name: "开宝箱",
        checked: false,
      },
      {
        name: "赚幸运券",
        checked: false,
      },
      {
        name: "广告充电",
        checked: true,
      },
      {
        name: "金币充电",
        checked: false,
      },
      {
        name: "换升阶器",
        checked: false,
        explain: '该功能可能由于识别碎片不准确而导致兑换失败。另外，有可能因识别问题兑换成保阶石，介意勿用！'
      },
      {
        name: "加游戏券",
        checked: true,
      },
      // {
      //   name: "资产升阶",
      //   checked: false,
      //   explain: '当资产的时效小于1天时即会触发升阶操作，升阶不会添加任何辅助物品，且会忽视斗战圣佛的升阶，如果介意请勿使用！！！'
      // },
      // {
      //   name: "转赠金币",
      //   checked: false,
      //   explain: '该功能使用方法：1、在包名配置中配置支付密码；2、在你的金币账号中添加一个金币接受人，将该接受人的备注改为【转赠金币】，同时需要把该接受人加入黑名单，脚本会在黑名单列表找到备注为【转赠金币】的用户进行赠送操作！'
      // },
    ],
  },
  hysj: {
    name: "环游世界",
    ig_official: {note: '环游世界', input: "com.yuedao.goworld"},
    id: 1,
    vip: true,
    funs: [
      {
        name: "签到",
        checked: true,
      },
      {
        name: "抽奖",
        checked: false,
        switch: false,
        title: '放弃翻倍'
      },
      {
        name: "开宝箱",
        checked: true,
      },
      {
        name: "极速合成",
        checked: false,
        explain: '包含新人体验'
      },
      {
        name: "智能合成",
        checked: false,
        explain: '每次运行该功能时会检测合成物是否达到10级，如果达到，会自动调用极速合成功能。'
      },
      {
        name: "自动合成",
        checked: true,
      },
      {
        name: "换升阶箱",
        checked: false,
        explain: '消耗所有碎片进行兑换！！！'
      },
      {
        name: "终极升阶",
        checked: false,
        explain: '升阶不会添加任何辅助道具！！！而且应该只会升级0阶'
      },
      {
        name: "充电赚钱",
        checked: false,
        explain: '会消耗所有可充电的未来之光进行充电，不区分未来之光等级！！！'
      },
      {
        name: '加游戏券',
        checked: false,
      },
      {
        name: '广告充电',
        checked: false,
      },
      {
        name: '游票买电',
        checked: false,
      },
      {
        name: '买升阶箱',
        checked: false,
      },
    ],
  },
  yysj: {
    name: "云游世界",
    ig_official: {note: '云游世界', input: "com.yunyou.android"},
    id: 1,
    vip: true,
    funs: [
      {
        name: "充电",
        checked: false,
      },
      {
        name: "转盘",
        checked: false,
      },
      {
        name: "离线收益",
        checked: false,
        explain: '该功能不会执行任何操作，只为了触发领取离线收益'
      },
      {
        name: "自动合成",
        checked: true,
      },
      {
        name: "智能合成",
        checked: false,
      },
    ],
  },
  // yytxg: {
  //   name: "柚柚头像馆",
  //   ig_official: {note: '柚柚头像馆', input: "com.zhiqu.yinxintong.yytxg"},
  //   id: 1,
  //   isReel: true,
  //   funs: [
  //     {
  //       name: "获取积分",
  //       checked: true,
  //     },
  //   ],
  // },
  jhmz: {
    name: "今后满座",
    ig_official: {note: '今后满座', input: "com.dunshen.jhmz"},
    id: 1,
    isReel: true,
    funs: [
      {
        name: "签到",
        checked: true,
      },
      {
        name: "活跃度",
        checked: false,
      },
      {
        name: "辅助充能",
        checked: false,
        explain: '该功能主要为了辅助手动充满爱心，脚本会在充能界面自动暂停，补满后需手动切换至任务中心界面，然后点击继续即可。'
      }
    ],
  },
  qct: {
    name: "千层塔",
    ig_official: {note: '千层塔', input: "com.elane.qiancengta"},
    id: 1,
    vip: true,
    funs: [
      {
        name: "参与闯关",
        checked: true,
      },
      {
        name: "幸运宝箱",
        checked: true,
      },
    ],
  },
  // yycj: {
  //   name: "云亦茶界",
  //   ig_official: {note: '云亦茶界', input: "com.yunyi.www"},
  //   id: 1,
  //   vip: true,
  //   funs: [
  //     {
  //       name: "茶园",
  //       checked: true,
  //     },
  //     {
  //       name: "圈子",
  //       checked: true,
  //     },
  //   ],
  // },
  reward: {
    name: '奖券世界',
    ig_official: {note: '奖券世界', input: 'com.caike.ticket'},
    id: 1,
    vip: true,
    funs: [
      {
        name: '开宝箱',
        checked: false,
        switch: false,
        title: '允许消耗钥匙'
      },
      {
        name: '充电',
        checked: false,
        switch: false,
        title: '优先电瓶供电',
        explain: '若开启，则视为需要优先使用超级电瓶供电，脚本不会进行购买电量的操作。'
      },
      {
        name: '转盘',
        checked: true,
        switch: false,
        title: '允许消耗广告次数'
      },
      {
        name: '建设国家',
        checked: true,
        switch: false,
        title: '极速合成'
      },
      // {
      //   name: '扩充背包',
      //   checked: true,
      //   explain: '该功能和建设国家冲突，会使用看广告获得的银币购买背包容量，从而导致没有银币去合成了，所以使用时这两个功能勾选一个即可。'
      // }, 
      {
        name: '出售建筑',
        checked: false,
        explain: '仅出售合成物（包括普通、珍贵、稀有、也会出售0阶和1阶故宫），请慎重使用该功能，会直接以交易所当前求购中对应建筑的价格出售，可卖数量由脚本自动识别，无需设置。'
      },
      {
        name: '加奖券',
        checked: false,
      },
      {
        name: '赠送金币',
        checked: false,
        iptval: '',
        input: '',
        explain: 'ID为接受人ID，同时可配置需要保留的金币个数。'
      },
      {
        name: '刷活跃度',
        checked: false,
        explain: '该功能以进行一次自动合成看广告为结束条件，用于小号为大号增加广告次数',
      },
      {
        name: 'config',   //  这个附加配置不要添加checked脚本就不会执行了
        iptval: [
          {
            name: '放弃离线收益',
            switch: false,
          },
        ]
      }
    ]
  },
  xxsj: {
    name: "消消世界",
    ig_official: {note: '消消世界', input: "com.jqsj.xxsj"},
    id: 1,
    vip: true,
    funs: [
      {
        name: "消除赚",
        checked: false,
      },
      {
        name: "领取体力",
        checked: true,
      },
      {
        name: "抽奖",
        checked: true,
      },
      {
        name: "投入奖券",
        checked: true,
      },
      {
        name: "自动消除",
        checked: true,
        title: '放弃翻倍',
        switch: false,
        explain: '普通号可通过开启放弃翻倍来减少广告占用时间，从而保证每个账号的任务都能做完。'
      },
      {
        name: "手动消除",
        checked: false,
        title: '放弃翻倍',
        switch: true,
        explain: '手动消除针对无法观看广告到用户开发，不会触发任何观看广告的行为，默认会放弃翻倍。'
      },
      {
        name: "广告赚",
        checked: false,
      },
      {
        name: '表情专刷',
        checked: false,
        switch: false,
        title: '放弃翻倍',
        explain: '所选表情之和的数量不低于44时触发消除操作！该功能不会消耗自动消除次数，由辅助进行消除操作。若是普通号使用且无法观看广告请开启放弃翻倍。',
        iptval: [],
        local: {
          title: '需要识别的表情',
          multiOptions: [
            {label: '魔鬼', value: '魔鬼'},
            {label: '黄金', value: '黄金'},
            {label: '表情兔', value: '表情兔'},
          ]
        }
      },
      {
        name: '赠送金币',
        checked: false,
        iptval: '',
      },
      {
        name: '出售表情',
        checked: false,
        explain: '【特殊表情】中的表情需打开才会进行出售，即使设置出售魔鬼，也会保留1.7万魔鬼用于广告赚和最低档的消除赚。',
        iptval: [],
        local: {
          title: '需要出售的特殊表情',
          multiOptions: [
            {label: '魔鬼', value: '魔鬼'},
            {label: '小丑', value: '小丑'},
            {label: '高级', value: '高级'},
          ]
        }
      },
    ],
  },
  worldcity: {
    name: "世界城",
    ig_official: {note: '世界城', input: "com.ainimei.worldcity"},
    id: 1,
    vip: true,
    funs: [
      {
        name: "砖块回收",
        checked: false,
        explain: '砖块回收只会把砖块出售给平台哦'
      },
      {
        name: '赠送金币',
        checked: false,
        iptval: '',
        input: '',
        explain: 'ID为接收人ID，同时可配置需要保留的金币个数。'
      },
      {
        name: "睡觉",
        checked: true,
      },
      {
        name: "找工作",
        checked: false,
        iptval: '郊区',
        local: {
          title: '工作地点',
          options: [
            {text: '郊区', value: '郊区'},
            {text: '工业区', value: '工业区'},
            {text: '商业区', value: '商业区'},
            {text: '高新区', value: '高新区'},
            {text: '金融区', value: '金融区'},
          ]
        }
      },
      {
        name: "选择行业",
        checked: false,
        iptval: '农业',
        isAttach: true,
        local: {
          title: '行业',
          options: [
            {text: '农业', value: '农业'},
            {text: '加工业', value: '加工业'},
            {text: '商业', value: '商业'},
            {text: '制造业', value: '制造业'},
          ]
        },
        explain: '此项为找工作的附加配置，无须勾选。'
      },
      {
        name: "选择食物",
        checked: false,
        iptval: '面包',
        isAttach: true,
        input: 1,
        local: {
          title: '食物',
          options: [
            {text: '面包', value: '面包'},
            {text: '薯条', value: '薯条'},
            {text: '汉堡', value: '汉堡'},
            {text: '蛋糕', value: '蛋糕'},
            {text: '臭豆腐', value: '臭豆腐'},
          ]
        },
        explain: '此项为找工作的附加配置，无须勾选。'
      },{
        name: "选择水",
        checked: false,
        iptval: '热水',
        isAttach: true,
        input: 1,
        local: {
          title: '水',
          options: [
            {text: '热水', value: '热水'},
            {text: '牛奶', value: '牛奶'},
            {text: '可乐', value: '可乐'},
            {text: '啤酒', value: '啤酒'},
            {text: '烈酒', value: '烈酒'},
          ]
        },
        explain: '此项为找工作的附加配置，无须勾选。'
      },
      {
        name: "职业升级",
        checked: false,
        iptval: '农民',
        local: {
          title: '职业',
          options: [
            {text: '农民', value: '农民'},
            {text: '加工师', value: '加工师'},
            {text: '商人', value: '商人'},
            {text: '制造师', value: '制造师'},
          ]
        }
      },
    ],
  },
  lomo: {
    name: "潮玩世界",
    ig_official: {note: '潮玩世界', input: "com.caike.lomo"},
    id: 1,
    vip: true,
    funs: [
      // {
      //   name: "买游侠兔",
      //   checked: false,
      //   iptval: '1',
      //   explain: '使用该功能需设置购买数量大于0，否则即使勾选也不会执行'
      // },
      // {
      //   name: "智能飞船",
      //   checked: true,
      //   explain: '建议配合【全局配置->下次运行等待】进行不间断轮号'
      // },
      // {
      //   name: '赠送NDTU',
      //   checked: false,
      //   iptval: '',
      // },
      // {
      //   name: '兑换金币',
      //   checked: false,
      //   explain: 'NDTU兑换成金币，全部兑换！全部兑换！全部兑换！！！'
      // },
      {
        name: '寻找矿场',
        checked: false,
        explain: '该功能包括寻找矿洞和开始挖矿~'
      },
      {
        name: '领取扭蛋',
        checked: false,
      },
      {
        name: '自动开蛋',
        checked: false,
      },
      {
        name: '挑战',
        checked: false
      },
      {
        name: '转宝石',
        checked: false,
        iptval: '',
        explain: '全部转赠！全部转赠！全部转赠！！！'
      },
      {
        name: '领天梯',
        checked: false,
        iptval: '',
        explain: '领取无聊猿天梯奖品'
      },
      {
        name: '领地下城',
        checked: false,
        iptval: '',
        explain: '领取地下城星球奖品'
      },
      {
        name: '挖宝加时',
        checked: false,
        iptval: '',
        explain: '方块兽自动挖宝加时，只检查是否可以免费加时，不消耗能量'
      },
    ],
  },
  scsz: {
    name: "生城世朝",
    ig_official: {note: '生城世朝', input: "com.shichao.game"},
    id: 1,
    vip: true,
    funs: [
      {
        name: "自动种植",
        checked: true,
      },
      {
        name: "极速合成",
        checked: false,
      },
      {
        name: "抽奖",
        checked: true,
      },
      {
        name: "幸运抽奖",
        checked: true,
      },
      {
        name: "补充营养",
        checked: true,
      },
      {
        name: "赠送种子",
        checked: false,
        iptval: '',
        explain: '接受人ID，赠送为全部赠送。'
      },
    ],
  },
  // dbk: {
  //   name: "打爆款",
  //   ig_official: {note: '打爆款', input: "com.yiqunkeji.dabaokuan"},
  //   id: 1,
  //   vip: true,
  //   funs: [
  //     {
  //       name: "取款",
  //       checked: true,
  //     },
  //     {
  //       name: "打造爆款",
  //       checked: true,
  //     },
  //     {
  //       name: "智能订单",
  //       checked: true,
  //       explain: [
  //         '1、使用智能订单功能需确保你已解锁加工厂内的各品阶成品，建议尽量早日全部解锁，避免订单中有用到的成品还没有解锁而导致辅助出错。',
  //         '2、因为该功能比较复杂，所以还是不能避免出现一些错误，请及时反馈，尽量录屏加日志发给作者。',
  //         '3、运行机制：助手会分析订单中需要的成品并进行制作，而为了保证广告效益最大化，脚本会智能补充一些当前订单中不需要的农作物等产品，这属于脚本内置逻辑，不用反馈给作者。'
  //       ]
  //     },
  //   ],
  // },
  
  
  dz: {
    name: "达赞",
    ig_official: {note: '达赞', input: "com.dazan.chat.android"},
    id: 1,
    isReel: true,
    funs: [
      {
        name: "签到",
        checked: true,
        explain: '该功能内置聊天发言，请提前修改一个好友备注为【笨笨朋友聊天】，一个群聊备注为【笨笨群聊发言】。发送内容为【你好】'
      }
    ],
  },
  lzsc: {
    name: "乐众商城",
    ig_official: {note: '乐众商城', input: "io.yichen.hulian"},
    id: 1,
    isReel: true,
    funs: [
      {
        name: "签到",
        checked: true,
      },{
        name: "赚积分",
        checked: true,
      },{
        name: "赚贡献",
        checked: false,
      },
    ],
  },
  jy: {
    name: "鲸鱼",
    ig_official: {note: '鲸鱼', input: "com.bbl.jyShortVideo"},
    id: 1,
    isReel: true,
    funs: [
      {
        name: "签到",
        checked: true,
      },
    ],
  },
  dm: {
    name: "东茂",
    ig_official: {note: '东茂', input: "com.wuhan.dongmaosyc"},
    id: 1,
    isReel: true,
    funs: [
      {
        name: "签到",
        checked: true,
      },
    ],
  },
  pig: {
    name: "一起来养猪",
    ig_official: {note: '一起来养猪', input: "com.yiqunkeji.yqlyz"},
    id: 1,
    funs: [
      {
        name: "红包",
        checked: true,
      },
      {
        name: "转盘",
        checked: true,
      },
      {
        name: "养猪",
        checked: true,
      },
      {
        name: "未婚结婚",
        checked: false,
        switch: false,
      },
      {
        name: "已婚分解",
        checked: false,
        textarea: [],
        textareaLabel: "设置白名单",
      },
    ],
  },
  lylml: {
    name: "旅游联盟",
    ig_official: {note: '旅游联盟链', input: "com.chain.tourist.master"},
    id: 1,
    isReel: true,
    funs: [
      {
        name: "签到奖励",
        checked: true,
      },
      {
        name: "活跃度",
        checked: true,
      }
    ],
  },
  cb: {
    name: "吃呗",
    ig_official: {note: '吃呗', input: "com.yumis.app"},
    id: 1,
    isReel: true,
    funs: [
      {
        name: "签到",
        checked: true,
      },
    ],
  },
  hyly: {
    name: "红蚁旅游",
    ig_official: {note: '红蚁旅游', input: "com.mg888.redant"},
    id: 1,
    isReel: true,
    funs: [
      {
        name: "签到",
        checked: true,
      },
    ],
  },
  lzst: {
    name: "乐赞生态",
    ig_official: {note: '乐赞生态', input: "com.app.lezan"},
    id: 1,
    isReel: true,
    funs: [
      {
        name: "签到",
        checked: true,
      },
    ],
  },
  oyyx: {
    name: "欧悦优选",
    ig_official: {note: '欧悦优选', input: "com.ouyue.oyshop"},
    id: 1,
    isReel: true,
    funs: [
      {
        name: "签到",
        checked: true,
      },
    ],
  },
  ysh: {
    name: "悦生活",
    ig_official: {note: '悦生活', input: "com.android.launcher"},
    id: 1,
    isReel: true,
    funs: [
      {
        name: "签到",
        checked: true,
      },
    ],
  },
  rfst: {
    name: "润福生态",
    ig_official: {note: '润福生态', input: "com.runfushengtai.app"},
    id: 1,
    isReel: true,
    funs: [
      {
        name: "签到",
        checked: true,
      },
    ],
  },
  shyx: {
    name: "山海优选",
    ig_official: {note: '山海优选', input: "com.tuanyou.tp.xdyx"},
    id: 1,
    isReel: true,
    funs: [
      {
        name: "签到",
        checked: true,
      },
    ],
  },
  zhcs: {
    name: "智慧城市",
    ig_official: {note: '智慧城市', input: "com.smartctiy.sz"},
    id: 1,
    isReel: true,
    funs: [
      {
        name: "签到",
        checked: true,
      },
    ],
  },
  zqsjlm: {
    name: "中企数据",
    ig_official: {note: '中企数据联盟', input: "com.adtie.klan.zhongq"},
    id: 1,
    isReel: true,
    funs: [
      {
        name: "签到",
        checked: true,
      },
    ],
  },
  yxq: {
    name: "猿星球",
    ig_official: {note: '猿星球', input: "com.du.metastar"},
    id: 1,
    isReel: true,
    funs: [
      {
        name: "签到",
        checked: true,
      },
      {
        name: "每日任务",
        checked: true,
      },
    ],
  },
  // yxyy: {
  //   name: "有喜有鱼",
  //   ig_official: {note: '有喜有鱼', input: "com.example.youxyouy"},
  //   id: 1,
  //   funs: [
  //     {
  //       name: "视频日常",
  //       checked: true,
  //     },
  //   ],
  // },
}