<template>
  <div class="Config-wrap">
    <van-tabs v-model:active="active" animated>
      <van-tab title="全局配置" name="全局配置">
        <van-collapse v-model="activeCollapse" :border="false" accordion class="project-collapse-wrap">
          <van-collapse-item title="常规项目" name="normal" :readonly="activeCollapse === 'normal'">
            <van-checkbox-group
              class="script-wrap"
              v-model="normalRunList"
              direction="horizontal"
            >
              <van-checkbox
                :name="item.value"
                shape="square"
                v-for="(item, index) in normalScriptList"
                :key="index"
                >
                <van-badge content="新" v-if="[].includes(item.name)" :offset="[8, 5]">
                  {{ item.name }}
                </van-badge>
                <span v-else>{{ item.name }}</span>
                </van-checkbox
              >
            </van-checkbox-group>
          </van-collapse-item>
          <van-collapse-item title="卷轴项目" name="reel" :readonly="activeCollapse === 'reel'">
            <van-checkbox-group
              class="script-wrap"
              v-model="reelRunList"
              direction="horizontal"
            >
              <van-checkbox
                :name="item.value"
                shape="square"
                v-for="(item, index) in reelScriptList"
                :key="index"
                >
                <van-badge content="新" v-if="['达赞'].includes(item.name)" :offset="[8, 5]">
                  {{ item.name }}
                </van-badge>
                <span v-else>{{ item.name }}</span>
                </van-checkbox
              >
            </van-checkbox-group>
          </van-collapse-item>
        </van-collapse>
        <van-cell-group>
          <div class="global-config">
            <van-field
              v-model="globals.ig_wait"
              label="下次运行等待"
              clearable
              placeholder=""
              type="number"
            >
              <template #extra>分</template>
            </van-field>
            <van-popover
              class="fun-explain-popover"
              theme="dark"
              placement="bottom-start"
              trigger="click"
              v-model:show="showPopover.popover1"
            > 
              运行完一轮后脚本下次执行的等待时间，单位为分钟，若运行完需要停止脚本，则设置为小于0的数字即可。
              <template #reference>
                <van-icon name="question-o" />
              </template>
            </van-popover>
            <van-field
              v-model="globals.ig_flyCount"
              label="切换飞行模式"
              clearable
              placeholder=""
              type="number"
            >
              <template #extra>个</template>
            </van-field>
            <van-popover
              class="fun-explain-popover"
              theme="dark"
              placement="bottom-end"
              trigger="click"
              v-model:show="showPopover.popover2"
            > 
              每运行了设置的包名个数后即开始进行飞行模式切换，设置的值大于0才会触发该行为。该功能仅针对使用流量卡的用户有效，且并不保证支持所有机型。
              <template #reference>
                <van-icon name="question-o" />
              </template>
            </van-popover>
            <van-cell center title="强停应用">
              <template #right-icon>
                <van-switch v-model="globals.ig_forceStop" size="18" />
              </template>
            </van-cell>
            <van-popover
              class="fun-explain-popover"
              theme="dark"
              placement="bottom-start"
              trigger="click"
              v-model:show="showPopover.popover3"
            > 
              启用后每次执行完功能就会强行关闭当前轮号的应用，对低性能设备可能有用。若清理后台有效，建议使用清理后台功能。
              <template #reference>
                <van-icon name="question-o" />
              </template>
            </van-popover>
            <van-cell center title="清理后台">
              <template #right-icon>
                <van-switch v-model="globals.ig_cleanBg" size="18" />
              </template>
            </van-cell>
            <van-popover
              class="fun-explain-popover"
              theme="dark"
              placement="bottom-end"
              trigger="click"
              v-model:show="showPopover.popover4"
            > 
              启用后每次执行完功能就会清理后台应用，对低性能设备可能有用。使用该功能需把本应用锁定在后台，否则会被清理掉导致掉线。
              <template #reference>
                <van-icon name="question-o" />
              </template>
            </van-popover>
            <van-cell center title="显示控制台">
              <template #right-icon>
                <van-switch v-model="globals.ig_showConsole" size="18" />
              </template>
            </van-cell>
            <van-popover
              class="fun-explain-popover"
              theme="dark"
              placement="bottom-start"
              trigger="click"
              v-model:show="showPopover.popover5"
            > 
              是否显示脚本运行日志
              <template #reference>
                <van-icon name="question-o" />
              </template>
            </van-popover>
            <van-cell center class="btn-cell-wrap">
              <template #value>
                图库：
                <van-button
                  type="primary"
                  size="mini"
                  @click="uploadCloseDialog.show = true"
                  >上传</van-button
                >
                <van-button
                  type="primary"
                  size="mini"
                  @click="viewCloseImg"
                  >浏览</van-button
                >
              </template>
            </van-cell>
            <van-popover
              class="fun-explain-popover"
              theme="dark"
              placement="bottom-end"
              trigger="click"
              v-model:show="showPopover.popover6"
            > 
              脚本执行时会加载图库，在图色脚本中会不断匹配图库中的图片，然后执行点击操作，通常用于关闭广告。该图库由所有用户共同维护，仅可上传，不能删除！
              <template #reference>
                <van-icon name="question-o" />
              </template>
            </van-popover> 
            <van-field
              v-model="globals.ig_interval"
              label="轮号间隔等待"
              clearable
              placeholder=""
              type="number"
            >
              <template #extra>秒</template>
            </van-field>
            <van-popover
              class="fun-explain-popover"
              theme="dark"
              placement="bottom-start"
              trigger="click"
              v-model:show="showPopover.popover7"
            > 
              每轮一个号之后的等待时间，设置为大于0的值时自动启用。脚本会在设定值的80%~120%之间随机创建等待时间（最少30秒）。例：①设定值为30秒，则随机范围为30~36；②设定值为60秒，则随机范围为48~72。
              <template #reference>
                <van-icon name="question-o" />
              </template>
            </van-popover>
          </div>
        </van-cell-group>
        <ul class="tip-wrap">
          <li>提示及免责声明：</li>
          <li>
            1、若使用过程中给你造成任何直接或间接损失，均与作者无关，使用即代表你同意此条款！！！
          </li>
          <li>
            2、包名：软件的唯一标识，每一款软件都会有，相当于我们的身份证，脚本是通过这个标识启动应用的。
          </li>
          <li>
            3、轮号：脚本根据配置的多个包名依次启动，并执行勾选的任务。
          </li>
          <li>
            4、轮号方式：①、通过分身软件制作分身，把分身的包名添加进包名配置即可；②、使用脚本内置的切号登录功能，该功能需要额外配置账号和密码（只有个别项目支持该轮号方案）。
          </li>
        </ul>
      </van-tab>

      <van-tab
        :title="firstItem.name"
        :name="firstItem.value"
        v-for="(firstItem, firstIndex) in checkedScriptList"
        :key="firstIndex"
      >
        <van-cell-group>
          <template v-if="configs[firstItem.value]">
            <template v-for="(item, index) in configs[firstItem.value].funs" :key="index">
              <config-item
                :data="item"
                :cur="firstItem.value"
              ></config-item>
            </template>
          </template>
          <div class="global-config">
            <van-cell center class="btn-cell-wrap">
              <template #value>
                  轮号：
                <van-button
                  type="primary"
                  size="mini"
                  @click="handlePkgNameDialog"
                  >配置包名</van-button
                >
              </template>
            </van-cell>
            <van-popover
              theme="dark"
              placement="bottom-start"
              trigger="click"
              class="fun-explain-popover"
              v-model:show="showPopover[`${firstItem.value}1`]"
            > 
              通常情况下，你不必关心此配置。但是你仍可以有个简单的了解。包名：相当于是软件的身份证，手机上安装的每款软件都会有一个唯一的包名。如果你配置了多个包名，脚本会对这些包名依次执行任务，这个行为就叫做轮号。
              <template #reference>
                <van-icon name="question-o" />
              </template>
            </van-popover>
            <van-cell center class="btn-cell-wrap" v-if="active !== '全局配置'">
              <template #value>
                <van-field label="序号：" v-model="configs[active].id" :label-width="50" style="padding: 0" clearable placeholder="开始序号"/>
              </template>
            </van-cell>
            <van-popover
              theme="dark"
              placement="bottom-end"
              trigger="click"
              class="fun-explain-popover"
              v-model:show="showPopover[`${firstItem.value}2`]"
            > 
              序号决定了脚本优先从第几个包名开始执行，该配置可以手动修改，修改后记得保存配置。
              <template #reference>
                <van-icon name="question-o" />
              </template>
            </van-popover>
          </div>  
          <div class="import-export-wrap">
            <van-uploader :after-read="importEvent" :max-count="1" accept="xls,xlsx">
              <van-button color="#FF6666" size="small"
                >导入包名配置</van-button
              >
            </van-uploader>
            <van-button color="#FF7F00" @click="exportEvent" size="small"
              >导出包名配置</van-button
            >
            <van-button type="primary" size="small"
            class="copy-template"
            :data-clipboard-text="'http://www.benben.icover.fun/pkgexport/包名配置模板.xlsx'"
              >下载模板</van-button>
            </div>  
            <div style="font-size: 12px;text-align: left; margin-top: 10px">提示：仅支持导入xls、xlsx格式的文件。若包名一样，则导入包名配置后不要忘记纠正包名！！！</div>
        </van-cell-group>
      </van-tab>
    </van-tabs>

    <div class="bottom-wrap">
      <van-button color="#FF6666" icon="upgrade" class="copy-btn"
        :data-clipboard-text="'https://wwm.lanzoub.com/b02icuftc'"
        >更新</van-button
      >
      <van-button color="#FF7F00" @click="QQGroupPopup = true"
        v-if="images.length"
        >QQ交流群</van-button
      >
      <van-button type="primary" @click="saveConfig"
      :loading="updating"
      loading-text="保存中..."
        >保存配置</van-button>
    </div>
  </div>


  <span class="export-pkg"
  ref="exportPkgRef"
  :data-clipboard-text="exportUrl"
  ></span>
 
 

  <!-- 配置多个应用包名 -->
  <div>
    <van-dialog
      class="pkg-name-wrap"
      v-model:show="pkgNameDialog"
      teleport="body"
      :showConfirmButton="false"
      overlay-class="recommend-project-overlay"
    >
      <div class="title-wrap">
        <div>序号</div>
        <div>备注</div>
        <div>应用包名</div>
      </div>
      <ul>
        <li v-for="(item, index) in pkgNameList" :key="index">
          <div class="top">
            <div class="num">{{index + 1}}</div>
            <van-field clearable v-model="item.note" placeholder="备注" />
            <van-field clearable v-model="item.input" placeholder="应用包名" />
          </div>
          <div class="top middle" v-if="couldSwitchAccount">
            <div class="num"></div>
            <van-field clearable v-model="item.ig_account" placeholder="账号" />
            <van-field clearable v-model="item.ig_pwd" placeholder="登录密码" />
          </div>
          <div class="top middle" v-if="['coinzb'].includes(active)">
            <div class="num"></div>
            <van-field clearable v-model="item.ig_pay" placeholder="支付密码" />
            <van-field clearable v-model="item.ig_payNote" placeholder="红包留言" />
          </div>
          <div class="bottom" v-if="from === 'bb' || !ConfigMap[active].vip">
            <div>
              <van-button type="success" size="mini" @click="addPkgName(index)">新增</van-button>
              <van-button type="success" size="mini" v-if="couldSwitchAccount" @click="copy(index)">复制</van-button>
              <van-button type="danger" size="mini" v-if="pkgNameList.length > 1" @click="deletePkgName(index)">删除</van-button>
              <van-button type="primary" size="mini" v-if="pkgNameList.length > 1 && index" @click="movePkgName('up', index)">上移</van-button>
              <van-button type="primary" size="mini" v-if="pkgNameList.length > 1 && index !== pkgNameList.length - 1" @click="movePkgName('down', index)">下移</van-button>
              <van-button type="warning" size="mini" @click="moveAll(index)" v-if="pkgNameList.length > 1 && index">此条以上移至末尾</van-button>
            </div>
          </div>
        </li>
      </ul>
      <div class="foot-btn-wrap">
        <div style="font-size: 12px;text-align: left; padding: 5px;">提示：仅显示可用包名数量的包名信息！</div>
        <div class="btns-wrap" :class="couldSwitchAccount && pkgNameList.length > 1 ? '' : 'two'">
          <van-button type="danger" @click="pkgNameDialog = false">取消</van-button>
          <van-button type="warning" @click="checkPkg" v-if="couldSwitchAccount && pkgNameList.length > 1">纠正包名</van-button>
          <van-button type="primary" @click="confirmAddPkgName">保存</van-button>
        </div>
      </div>
    </van-dialog>
  </div>

  <!-- 推荐项目 -->
  <van-image-preview
    v-model:show="QQGroupPopup"
    :images="images"
    closeable
    show-indicators
    class-name="recommend-project-image-preview"
    overlay-class="recommend-project-overlay"
  >
  </van-image-preview>

  <van-dialog v-model:show="galleryDialog.show" title="图库" confirm-button-text="关闭" class="gallery-dialog-warp">
    <p class="tip">说明：图片编号 <span style="color: red;">/</span> 匹配次数 </p>
    <ul class="list-wrap">
      <li v-for="(item, index) in galleryDialog.list" :key="index" class="item">
        <van-image
          :src="item.url"
          fit="scale-down"
          position="center"
        />
        <div class="number">{{ item.number }} <span style="color: red">/</span> {{ item.count }}</div>
      </li>
    </ul>
  </van-dialog>

  <van-dialog v-model:show="uploadCloseDialog.show" :beforeClose="confirmUploadClose" title="上传关闭图片" show-cancel-button class="upload-close-dialog-warp">
    <div class="content-wrap">
      <div class="name">图片：</div>
      <van-uploader v-model="uploadCloseDialog.files" accept="image/*" :max-count="1" :before-read="beforeReadCloseIcon"/>
    </div>
    <p class="tip">1、图片大小不能超过20KB，如果脚本匹配到了目标图片，则会点击图片的中心位置。</p>
    <p class="tip">2、若图片三天内没有被匹配过，则会自动从图库中删除。</p>
    <p class="tip">3、类似【放弃福利】【跳过】之类的操作，请联系作者全局处理。</p>
    <p class="tip">4、该图库所有用户共享，请勿恶意上传无关图片，否则将会被封禁账号。</p>
  </van-dialog>










  <van-dialog :beforeClose="beforeClose" v-model:show="showAnnouncement" :title="from === 'bb' ? `收费方案调整补充公告` : '暂停更新公告'" confirmButtonText="关闭">
      <ul class="tip-wrap">
        <li class="normal" v-for="(item, index) in announcements" :key="index">
          {{index + 1}}、
          {{item}}
        </li>
      </ul>
      <div class="not-tip-wrap">
        <van-checkbox v-model="noneTip" shape="square" size="small">不再提示</van-checkbox>
      </div>
    </van-dialog>
</template>

<script>
import {reactive, toRefs, computed, getCurrentInstance} from 'vue'
import Clipboard from "clipboard";
import ConfigMap from "./ConfigMap.js";
import { useStore } from 'vuex'
import { read, utils, writeFile, writeFileXLSX, write } from 'xlsx';
import { saveAs } from 'file-saver';
import { ImagePreview } from 'vant';

export default {
  name: "Config",
  props: [],
  components: {},
  setup() {
    const {
      appContext: {
        config: {
          globalProperties: { from },
        },
      },
    } = getCurrentInstance();

    let email = localStorage.getItem("email")?.trim() || ''
    let coinzb = {
      name: "金币专版",
      ig_official: {note: '金币专版', input: "com.atanytime.sschat"},
      id: 1,
      in: ['bb'],
      funs: [
        {
          name: "激活奖励",
          checked: false,
        },
        {
          name: "抽奖",
          checked: true,
          switch: false,
          title: '放弃翻倍'
        },
        {
          name: "开宝箱",
          checked: true,
        },
        {
          name: "自动合成",
          checked: true,
        },
        {
          name: "智能合成",
          checked: true,
          explain: '该功能为脚本进行等级识别合成，未满级请使用此功能（含自动回收）'
        },
        {
          name: "签到",
          checked: true,
        },
        {
          name: "赚广告券",
          checked: true,
        },
        {
          name: "赚幸运券",
          checked: true,
        },
        {
          name: "广告充电",
          checked: true,
        },
        {
          name: "金币充电",
          checked: false,
        },
        {
          name: "加广告券",
          checked: true,
        },
        {
          name: "加游戏券",
          checked: true,
        },
        {
          name: "资产升阶",
          checked: false,
          explain: '当资产的时效小于1天时即会触发升阶操作，升阶不会添加任何辅助物品，且会忽视斗战圣佛的升阶，如果介意请勿使用！！！'
        },
        {
          name: "转赠金币",
          checked: false,
          explain: '该功能使用方法：1、在包名配置中配置支付密码；2、在你的金币账号中添加一个金币接受人，将该接受人的备注改为【转赠金币】，同时需要把该接受人加入黑名单，脚本会在黑名单列表找到备注为【转赠金币】的用户进行赠送操作！'
        },
      ],
    }
    if (email.includes('40388761') || email.includes('24816588') || email.includes('22960326')) {
      ConfigMap.coinzb = coinzb
    }
   

    if (email.includes('24816588') && !ConfigMap.hysj.funs.find(x => x.name === '发红包')) {
      ConfigMap.hysj.funs.push({
        name: '发红包',
        checked: false
      })
    }


    if (['49826386@qq.com', '248165884@qq.com', '248165885@qq.com'].includes(email)) {
      if (!ConfigMap.hysj.funs.find(x => x.name === '抢单')) {
        ConfigMap.hysj.funs.push({
          name: '抢单',
          checked: false,
          iptval: 1,
        })
      }
    }

    let store = useStore()
    let normalScriptList = []
    let reelScriptList = []
    for (const key in ConfigMap) {
      if (ConfigMap[key].isReel) {
        reelScriptList.push({
          name: ConfigMap[key].name,
          value: key,
        })
      } else {
        normalScriptList.push({
          name: ConfigMap[key].name,
          value: key,
        })
      }
    }
    const userInfo = computed(() => store.state.userInfo)
    const inviteInfo = computed(() => store.state.inviteInfo)


    let announcements = from === 'bb' ? [
      '鉴于有小伙伴没有明白之前公告的含义，特做此解释说明。',
      '改动一：基础时长在原有基础上额外赠送4个可用设备，共计5个可用设备。',
      '改动二：购买的轮号需要与项目绑定：给环游购买的轮号金币世界不能用。收费标准不变（此前公告中收费方案考虑不周，无法实现），如果你是单项目脚本用户，此项调整对你没有影响；如果你环游和金币都需要不限轮号，则需要分别为这两个项目购买不限轮号，相当于多了一次轮号费用。',
      '该方案自公告之日(2023-07-18)起，一个月内实施。'
    ] : [
      '由于个人原因，笨笨合集自即日(2023-07-18)起，将不再添加新的项目脚本（笨笨助手同步脚本除外）。',
      '精力有限，未来一年内暂停更新合集，感谢各位的支持，抱歉(0_0)'
      ]
    store.commit('ALLSCRIPTLIST', [...normalScriptList, ...reelScriptList])
    
    let data = reactive({
      email,
      // 保存当前用户的常用项目列表状态(包含选中和未选中)
      normalScriptList,   // 其他项目
      reelScriptList, // 卷轴项目
      updating: false,
      loadingText: '保存中...',
      delayUnit: 0.2,
      uploadCloseDialog: {
        show: false,
        files: [],
      },
      galleryDialog: {
        show: false,
        list: []
      },
      normalRunList: [],
      reelRunList: [],
      activeCollapse: 'normal',
      ConfigMap: JSON.parse(JSON.stringify(ConfigMap)),
      exportUrl: '',




      showAnnouncement: false,
      noneTip: false,
      announcements,
    })
    const checkedScriptList = computed(() => {
      let list1 = data.normalScriptList.filter(x => data.normalRunList.includes(x.value))
      let list2 = data.reelScriptList.filter(x => data.reelRunList.includes(x.value))
      return [...list1, ...list2]
    })
   
    return {
      ...toRefs(data),
      userInfo,
      inviteInfo,
      checkedScriptList,
    }
  },
  data() {
    return {
      active: "全局配置",   // 全局配置   reward dbk
      configs: {},
      globals: {
        // 全局配置以此处为准，像endTime是在服务端读取数据库添加后返回至笨笨助手中的，配置中不用保存这些东西
        ig_showConsole: true,
        ig_forceStop: false,
        ig_cleanBg: false,
        ig_wait: -1,
        ig_flyCount: 0,
        ig_interval: -1,
      },
      pkgNameDialog: false,
      pkgNameList: [
        // {note: '备注', input: ''},
      ],
      baseNote: '',
      QQGroupPopup: false,
      showPopover: {},
      uselessKeys: ['local', 'explain', 'title', 'isAttach'],
    };
  },
  computed: {
    images() {
      return [`http://www.benben.icover.fun/leader/${this.from}.jpg`]
    },
    // 含有脚本切号功能的脚本
    couldSwitchAccount() {
      return ['coinworld', 'jhmz', 'coinzb', 'hysj', 'yycj', 'lzsc', 'kxjz'].includes(this.active)
    },
  },
  watch: {
  },
  created() {
    this.getConfig();
  },
  mounted() {
    let clipboard = new Clipboard(".copy-btn", {});
    clipboard.on("success", (e) => {
      this.$dialog.alert({
        message: "已复制下载链接至剪贴板，请在浏览器内打开下载，密码：9999",
      });
    });
    

    let copyTemplate = new Clipboard(".copy-template", {});
    copyTemplate.on("success", (e) => {
      this.$dialog.alert({
        message: "已复制下载链接至剪贴板，请在浏览器内打开下载",
      });
    });


    let exportAction = new Clipboard('.export-pkg', {});
    exportAction.on("success", (e) => {
      this.$dialog.alert({
        message: "已复制下载链接至剪贴板，请在浏览器内打开下载",
      });
    });

    // let noneTipTime = localStorage.getItem('fixedAnnounce') || ''
    // if (noneTipTime != 2) {
    //   this.showAnnouncement = true
    // }
  },
  methods: {
    beforeClose(){
      // 固定公告
      if(this.noneTip){
        localStorage.setItem('fixedAnnounce', '2')
      }else{
        localStorage.setItem('fixedAnnounce', '')
      }
      return true
    },
    importEvent(args) {
      let { file } = args
      if (!file.name.includes('xls') && !file.name.includes('xlsx')) {
        this.$toast('仅支持导入xls、xlsx格式的文件')
        return
      }
      const reader = new FileReader();
      reader.onloadend = (progressEvent) => {
        const arrayBuffer = reader.result;
        const workbook = read(arrayBuffer, { type: 'array' });
        let worksheet = workbook.Sheets[workbook.SheetNames[0]]; //获取第一个Sheet
        let result = utils.sheet_to_json(worksheet); //json数据格式
        if (!result.length) {
          this.$toast('至少导入一条数据！')
          return
        }
        let keyMap = {
          账号: 'ig_account',
          登录密码: 'ig_pwd',
          支付密码: 'ig_pay',
          应用包名: 'input',
          备注: 'note'
        }
        this.pkgNameList = result.map(x => {
          let obj = {}
          for (const key in keyMap) {
            obj[keyMap[key]] = x[key] || ''
          }
          return obj
        })
        this.confirmAddPkgName(true)
      };
     reader.readAsArrayBuffer(file);
    },
    exportEvent() {
      let params = {
        email: this.email,
        script: this.active
      }
      this.axios.get('pkgs/export', { params }).then(res => { 
        this.exportUrl = `http://www.benben.icover.fun/pkgexport/${res.data}`
        // this.$forceUpdate()
        this.$nextTick(() => {
          this.$refs.exportPkgRef.click()
        })
      })
    },
    beforeReadCloseIcon(file) {
      if (!file.type.startsWith('image/')) {
        this.$toast('只能上传图片')
        return
      }
      if(file.size > 20 * 1024){
        this.$toast(`图片大小不能超过20KB`)
        return
      }
      return file
    },
    confirmUploadClose(type) {
      if (type === 'confirm') {
        if (!this.email) {
          this.$toast('账号信息有误')
          return
        }
        if (!this.uploadCloseDialog.files.length) {
          this.$toast('请选择图片')
          return
        }
        let formData = new FormData()
        formData.append(`file`, this.uploadCloseDialog.files[0].file)
        return this.axios.post(`close/upload?email=${this.userInfo.email}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(res => {
          this.$toast.success('上传成功')
          this.uploadCloseDialog.files = []
          this.getCloseImg()
          return true
        }).catch(res => {
          return false
        })
      }
      return true
    },  
    viewCloseImg() {
      this.galleryDialog.show = true
      this.getCloseImg()
    },
    getCloseImg() {
      this.axios.get('close', {
        params: {email: this.userInfo.email}
      }).then(res => {
        let list = res.data || []
        this.galleryDialog.list = list.map(x => {
          x.url = `http://www.benben.icover.fun/gallery/${x.number}.png`
          x.number = x.number.substr(2)
          x.count = x.count < 1000 ? x.count : (x.count < 10000 ? `${this.$calc(x.count).div(1000).end()}k` : `${this.$calc(x.count).div(10000).end()}w`)
          return x
        })
      })
    },
    checkPkg(){
      this.pkgNameList.forEach(x => {
        x.input = x?.input?.trim() ||''
        x.ig_account = x?.ig_account?.trim() ||''
        x.ig_pwd = x?.ig_pwd?.trim() ||''
        x.ig_pay = x?.ig_pay?.trim() ||''
      })
      this.pkgNameList.some((x, i) => {
        let pkg = x.input.split('@')[0]
        x.input = `${pkg}@${i + 1}`
      })
    },
    // 获取包名配置列表
    async getPkgNameList() {
      let params = {
        email: this.email,
        script: this.active
      }
      this.pkgNameList = [];
      return await this.axios.get('pkgs', { params }).then(res => {
        let pkgList = res?.data || []
        if (!pkgList.length) {
          pkgList = [this.configs[this.active].ig_official]
        }
        this.pkgNameList = pkgList
        return pkgList
      }).catch(res => {
        return []
      })
    },
    async handlePkgNameDialog() {
      this.pkgNameList = await this.getPkgNameList()
      this.baseNote = this.configs[this.active].name
      this.pkgNameDialog = true;
    },
    removePkgName(index) {
      this.pkgNameList.splice(index, 1);
    },
    addPkgName(index) {
      if (this.from === 'reel' && this.pkgNameList.length >= 50) {
        this.$toast('一个账号最多只能添加50个包名!')
        return
      }
      this.pkgNameList.splice(index + 1, 0, {note: this.baseNote, input: ''});
    },
    copy(index) {
      if (this.from === 'reel' && this.pkgNameList.length >= 50) {
        this.$toast('一个账号最多只能添加50个包名!')
        return
      }
      this.pkgNameList.splice(index + 1, 0, {...this.pkgNameList[index], ig_account: ''});
    },
    deletePkgName(index){
      this.pkgNameList.splice(index, 1)
    },
    movePkgName(action, index) {
      if (action === "up") {
        this.pkgNameList.splice(
          index - 1,
          0,
          this.pkgNameList.splice(index, 1)[0]
        );
      } else if (action === "down") {         
        this.pkgNameList.splice(
          index + 1,
          0,
          this.pkgNameList.splice(index, 1)[0]
        );
      } else {
      }
    },
    moveAll(index){
      let arr = this.pkgNameList.splice(0, index)
      this.pkgNameList = [...this.pkgNameList, ...arr]
    },
    confirmAddPkgName(fromImport) {
      if (this.from === 'reel' && this.pkgNameList.length >= 50) {
        this.$toast('最多只能添加50个包名!')
        return
      }
      this.pkgNameList.forEach(x => {
        x.input = x.input ? (x.input + '')?.trim() : ''
        x.ig_account = x.ig_account ? (x.ig_account + '')?.trim() : ''
        x.ig_pwd = x.ig_pwd ? (x.ig_pwd + '')?.trim() : ''
        x.ig_pay = x.ig_pay ? (x.ig_pay + '')?.trim() : ''
      })
      let needRectify = false // 是否需要纠正包名，当为导入时，检查完自动纠正包名
      let fail = this.pkgNameList.some((x, i) => {
        if(!x.input){
          this.$toast(`第${i + 1 + (fromImport ? 1 : 0)}项包名不能为空`)
          return true
        }
        if (fromImport) {
          if (!needRectify) {
            let repeatIdx = this.pkgNameList.findIndex((y, j) => i != j && x.input === y.input)
            if(repeatIdx !== -1){
              needRectify = true
            }
          }
        } else {
          let repeatIdx = this.pkgNameList.findIndex((y, j) => i != j && x.input === y.input)
          if(repeatIdx !== -1){
            this.$toast(`第${repeatIdx + 1}项与第${i + 1}项包名重复，请点击纠正按钮进行自动纠正`)
            return true
          }
        }
        
      })
      if (fail) { return }
      if (needRectify) {  // 纠正包名
        this.checkPkg()
      }
      let pkgs = this.$_.cloneDeep(this.pkgNameList)
      // 是合集且如果保存的是vip项目，则只能保存一个包名
      if (this.from === 'reel' && this.ConfigMap[this.active].vip) {
        pkgs = pkgs.slice(0, 1)
      }
      let params = {
        email: this.email,
        script: this.active,
        pkgs
      }
      this.$store.commit('LOADING', true)
      this.axios.post('pkgs', params).then(res => {
        this.$toast.success(`包名${fromImport ? '导入' : '保存'}成功`);
        this.pkgNameDialog = false
      }).finally(() => {
        this.$store.commit('LOADING', false)
      })
      
    },
    getConfig() {
      if (!this.email) {
        return;
      } 
      this.axios.get(`config`, {
          params: {
            email: this.email?.trim(),
          },
        })
        .then((res) => {
          let {configs = {}, globals = {}, runList = []} = res.data || {}
          if (!Object.keys(configs).length) {
            this.configs = ConfigMap
            this.$toast("新用户使用前请务必保存配置！");
            return
          }

          this.normalRunList = runList.filter(x => !this.ConfigMap[x].isReel)
          this.reelRunList = runList.filter(x => this.ConfigMap[x].isReel)
          let final = {}
          for (const key in ConfigMap) {
            // 只取configmap的funs数组对象中有的属性
            final[key] = this.$_.merge({}, ConfigMap[key], this.$_.pick(configs[key], ['name', 'id']))
            final[key].funs = ConfigMap[key]?.funs?.map(x=> {
              let cur = configs[key]?.funs?.find(y => y.name === x.name) || {}
              let usefulObj = this.$_.omit(cur, Object.keys(x).filter(x => this.uselessKeys.includes(x)))
              return this.$_.merge(x, usefulObj)
            }) || []
          }
          this.configs = final
          this.globals = this.$_.merge(this.globals, this.$_.pick(globals, Object.keys(this.globals)))
        }).catch(err => {
          this.configs = ConfigMap
          this.$toast("新用户使用前请务必保存配置！");
        })
    },
    saveConfig() {
      let allRunList = [...this.normalRunList, ...this.reelRunList]
      if (!allRunList.length) {
        this.$toast("必须要勾选一个脚本哦~");
        return;
      }
      
      // 删除无需保存至服务器的字段
      let configs = this.$_.cloneDeep(this.configs)
      for (const key in configs) {
        configs[key].funs = configs[key].funs.map(x => {
          let obj = this.$_.omit(x, this.uselessKeys)
          return obj
        })
        delete configs[key].vip
        delete configs[key].isReel
      }
     
      
      this.updating = true
      this.axios.post(`config/save`, {
          configs,
          globals: this.globals,
          runList: allRunList,
          email: this.email?.trim(),
          name: this.curConfig
        })
        .then((res) => {
          if (res.code === 0) {
            this.$toast.success("配置保存成功");
          } else {
            this.$toast.fail(res.msg);
          }
        }).finally(() => {
          this.updating = false
        })
    }
  },
};
</script>

<style scoped lang="less">
@keyframes light{
  50%{
    filter: hue-rotate(360deg) blur(1px);
    color: red;
    text-shadow:0 0 1px orange,
                0 0 2px orange;
  }
}
.Config-wrap {
  /deep/ .choose-config-wrap{
    background: #fff;
    border-bottom: 2px solid #dedede;
    .van-dropdown-menu__bar{
      height: 36px;
    }
    .edit-wrap{
      color: #1989fa;
    }
  }
  /deep/ .van-tabs {
    .van-tabs__line {
      min-width: 20%;
    }
    .van-tabs__content {
      max-height: calc(100vh - 166px);
      overflow-y: auto;
    }
    .van-cell-group {
      padding: 5px 15px 15px;
      .global-config{
        display: grid;
        grid-template-columns: calc(50% - 30px) 30px calc(50% - 30px) 30px;
        align-items: center;
        row-gap: 8px;
        padding-top: 10px;
      }
      .import-export-wrap{
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        >*{
          width: 30%;
        }
      }
      .van-cell {
        height: 28px;
        background: #efefef;
        align-items: center;
        padding: 0 5px;
        .van-field__label {
          color: #323233;
          width: auto;
          margin-right: 5px;
        }
        &.btn-cell-wrap {
          .van-cell__value {
            display: flex;
            justify-content: space-around;
            align-items: center;
          }
        }
      }
      .van-field {
        input {
          color: #ff4040;
          text-align: center;
        }
        .van-icon-clear{
          padding-left: 0;
        }
      }
      
    }
  }
  /deep/ .project-collapse-wrap{
    text-align: left;
    .van-collapse-item{
      .van-cell{
        background: #fff !important;
      }
      .script-wrap {
        display: grid;
        grid-template-columns: repeat(3, auto);
        row-gap: 10px;
        .van-checkbox{
          overflow: unset;
        }
      }
    }
    
  }


  .bottom-wrap {
    position: fixed;
    bottom: 60px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    .van-button{
      width: 30%;
      padding: 0
    }
  }
}
</style>
<style lang="less">
.tip-popup-wrap {
  width: 90%;
  border-radius: 10px;
  .tip-wrap {
    margin-top: 0 !important;
    padding: 10px 15px !important;
    background: #fff;
  }
}
.common-use-popup-wrap {
  width: 80%;
  border-radius: 10px;
  .common-use-wrap {
    .title {
      font-size: 18px;
      line-height: 42px;
      padding-left: 10px;
    }
    .van-row {
      padding: 15px 15px 0;
      .van-col {
        margin-bottom: 15px;
      }
    }
  }
}
.pkg-name-wrap {
  border-radius: 0;
  width: 100%;
  height: calc(100% - 50px);
  top: 0;
  left: 0;
  transform: none;
  box-sizing: border-box;
  .van-dialog__header{
    padding: 0;
    line-height: 40px;
    border-bottom: 1px solid rgb(235, 237, 240);
  }
  .van-dialog__content{
    height: 100%;
    padding: 0;
    .title-wrap{
      padding: 8px 0;
      border-bottom: 1px solid rgb(235, 237, 240);
      margin-bottom: 10px;
      padding-right: 5px;
      display: grid;
      align-items: center;
      grid-template-columns: 50px 30% calc(100% - 50px - 30% - 12px);
      column-gap: 6px;
      text-align: center;
      color: #000;
      font-size: 14px;
    }
    ul{
      overflow-y: auto;
      height: calc(100% - 117px);
      li{
        margin-bottom: 10px;
        padding-bottom: 5px;
        padding-right: 5px;
        &:not(:last-child){
          border-bottom: 1px solid #efefef;
        }
        .top{
          display: grid;
          align-items: center;
          grid-template-columns: 50px 30% calc(100% - 50px - 30% - 12px);
          column-gap: 6px;
          text-align: center;
          &.middle{
            margin-top: 5px;
          }
          .van-cell{
            box-sizing: border-box;
            padding: 0 3px;
            background: #efefef;
            .van-cell::after{
              display: none;
            }
            .van-field__body{
              input{
                color: #ff4040;
              }
            }
          }
        }
        .bottom{
          margin-top: 2px;
          display: flex;
          justify-content: flex-end;
        }
      }
    }

  }
  .foot-btn-wrap{
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    background: #fff;
    border-top: 1px solid #efefef;
    .btns-wrap{
      display: grid;
      grid-template-columns: repeat(3, calc((100% - 20px) / 3));
      &.two{
        grid-template-columns: repeat(2, calc((100% - 10px) / 2));
      }
      column-gap: 10px;
      button{
        color: #fff;
      }
    }
  }
}
.gallery-dialog-warp{
  .van-dialog__content{
    padding: 10px 15px;
    max-height: 65vh;
    overflow-y: auto;
    p.tip{
      text-align: left;
      font-size: 14px;
      margin-bottom: 10px;
    }
    .list-wrap{
      display: grid;
      grid-template-columns: repeat(3, calc((100% - 3 * 5px) / 3));
      align-items: center;
      column-gap: 10px;
      row-gap: 10px;
      font-size: 12px;
      .item{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .van-image{
          border: 1px solid #bcbcbc;
          border-radius: 6px;
          .van-image__img{
            height: 80px !important;
            max-height: unset !important;
          }
        }
        .number,.count{
          display: flex;
          align-items: center;
          justify-content: space-around;
          padding: 5px 0;
        }
      }
    }
  }
}
.upload-close-dialog-warp{
  .van-dialog__content{
    .content-wrap{
      display: grid;
      grid-template-columns: 70px auto;
      row-gap: 20px;
    }
    .tip{
      text-align: left;
      font-size: 14px;
      color: grey;
      padding: 0 12px;
      margin-top: 8px;
    }
  }
}


.recommend-project-overlay,
.recommend-project-image-preview {
  height: calc(100% - 50px);
}
</style>